import React, { useEffect, useRef } from "react";
import {
  Box,
  VStack,
  HStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  IconButton,
  Button,
  Divider,
  Heading,
  useDisclosure,
  useBreakpointValue,
  useColorModeValue,
  useToast,
  Text,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import {  FiUpload } from "react-icons/fi";
import { MdQuestionAnswer } from "react-icons/md";
import { FiFileText } from "react-icons/fi";
import { AiOutlineFileDone } from "react-icons/ai";
import { FaPlus, FaListAlt } from "react-icons/fa";
import {
  Outlet,
  NavLink,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import { FaUser, FaBrain, FaChartPie, FaSignOutAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import FeatureList from "./FeaturesList";
import { hitBeApi } from "../../api/api";

const Dashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const isNoLayoutRoute = ["/dashboard"].includes(location.pathname);
  const navWidth = useBreakpointValue({ base: "100%", lg: "300px" });
  const linkHoverBg = useColorModeValue("gray.200", "gray.700");
  const navBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const isLargeScreen = useBreakpointValue({ base: false, lg: true });
  const isTalkConversationsHistoryCalled = useRef(false);
  const isFetchPdfUploadsCalled = useRef(false);
  const isFetchOmrUploadsCalled = useRef(false);
  const fetchExamAttemptsCalled = useRef(false);
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const MotionVStack = motion(VStack);
  const attemptsFromState = useSelector((state) => state.attempts);

  const toggleDrawer = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  const handleLogout = () => {
    document.cookie = "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    toast({
      title: "Logout Success.",
      description: "Logout successful along-with browser cache removed.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/");
  };

  const fetchTalkConversationsHistory = async () => {
    if (isTalkConversationsHistoryCalled.current) return;
    isTalkConversationsHistoryCalled.current = true;
    try {
      const response = await hitBeApi(`chat/talk/history`, "GET");
      dispatch({
        type: "SET_VOICE_CONVERSATIONS",
        value: response?.data,
      });
    } catch (error) {
      console.error("Error fetching user talk conversations details:", error);
      if (error.message.includes("Unauthorized")) {
        navigate("/");
        document.cookie =
          "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
        toast({
          title: "Error",
          description: "Unauthorized: Invalid token, please login again",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const fetchPdfUploadsHistory = async () => {
    if (isFetchPdfUploadsCalled.current) return;
    isFetchPdfUploadsCalled.current = true;
    try {
      const response = await hitBeApi(`pdf/uploads`, "GET", null);
      dispatch({
        type: "SET_USER_PDF_UPLOADS",
        value: response?.data?.allUploadsResResponse,
      });
    } catch (error) {
      console.error("Error fetching user's pdf Q&A's uploads details:", error);
      if (error.message.includes("Unauthorized")) {
        navigate("/");
        document.cookie =
          "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
        toast({
          title: "Error",
          description: "Unauthorized: Invalid token, please login again",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const fetchOmrUploadsHistory = async () => {
    if (isFetchOmrUploadsCalled.current) return;
    isFetchOmrUploadsCalled.current = true;
    try {
      const response = await hitBeApi(`omr/uploads`, "GET", null);
      dispatch({
        type: "SET_USER_OMR_UPLOADS",
        value: response?.data?.omrEvaluateResponse,
      });
    } catch (error) {
      console.error("Error fetching user's omr uploads details:", error);
      if (error.message.includes("Unauthorized")) {
        navigate("/");
        document.cookie =
          "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
        toast({
          title: "Error",
          description: "Unauthorized: Invalid token, please login again",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    const fetchExams = async () => {
      if (fetchExamAttemptsCalled.current) return;
      fetchExamAttemptsCalled.current = true;
      try {
        const response = await hitBeApi("exams/user/attempts", "GET");
        dispatch({
          type: "SET_ATTEMPTS",
          value: response.data.attempts,
        });
      } catch (error) {
        console.error("Error fetching user's exam attempts:", error);
        if (error.message.includes("Unauthorized")) {
          navigate("/");
          document.cookie =
            "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
          toast({
            title: "Error",
            description: "Unauthorized: Invalid token, please login again",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Failed to fetch exams.",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    if (attemptsFromState.length === 0) {
      fetchExams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTalkConversationsHistory();
    fetchPdfUploadsHistory();
    fetchOmrUploadsHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isActive = (path) => location.pathname === path;

  const NavLinks = () => {
    // Common styles for related links
    const relatedLinkStyles = {
      variant: "ghost",
      colorScheme: "teal",
      size: "lg",
      width: "full",
      justifyContent: "flex-start",
      _hover: { bg: linkHoverBg },
    };

    // Link styles for "New Mock Test" and "All Mock Tests"
    const mockTestLinkStyles = {
      ...relatedLinkStyles,
      borderLeft: "4px solid red",
      pl: 4,
    };

    // Link styles for "Evaluate Pdf Q&As" and "ALL PDF Q&A"
    const pdfLinkStyles = {
      ...relatedLinkStyles,
      borderLeft: "4px solid blue",
      pl: 4,
    };

    // Link styles for "Ask Question" and "All Asked Questions"
    const questionLinkStyles = {
      ...relatedLinkStyles,
      borderLeft: "4px solid purple",
      pl: 4,
    };

    // Link styles for "AI Mock Test" and "AI Learning"
    const aiLinkStyles = {
      ...relatedLinkStyles,
      borderLeft: "4px solid yellow",
      pl: 4,
    };

    // Link styles for "Analytics"
    const analyticsLinkStyles = {
      ...relatedLinkStyles,
      borderLeft: "4px solid white", // Use a different color for Analytics
      pl: 4,
      colorScheme: "blue", // Unique color scheme
    };

    return (
      <>
        <NavLink to="profile" exact="true">
          <Button
            leftIcon={<FaUser />}
            {...relatedLinkStyles}
            isActive={isActive("/profile")}
          >
            Profile
          </Button>
        </NavLink>

        <NavLink to="new-ai-mock-test" exact={"true"}>
          <Button
            leftIcon={<FaPlus />}
            {...mockTestLinkStyles}
            isActive={isActive("/new-ai-mock-test")}
          >
            New Mock Test
          </Button>
        </NavLink>

        <NavLink to="all-mock-tests" exact={"true"}>
          <Button
            leftIcon={<FaListAlt />}
            {...mockTestLinkStyles}
            isActive={isActive("/all-mock-tests")}
          >
            All Mock Tests
          </Button>
        </NavLink>

        <NavLink to="evaluate-pdf" exact="true">
          <Button
            leftIcon={<FiFileText />}
            {...pdfLinkStyles}
            isActive={isActive("/evaluate-pdf")}
          >
            Evaluate Pdf Upload
          </Button>
        </NavLink>

        <NavLink to="evaluate-pdf-results" exact="true">
          <Button
            leftIcon={<AiOutlineFileDone />}
            {...pdfLinkStyles}
            isActive={isActive("/evaluate-pdf-results")}
          >
            ALL Evaluated Pdfs
          </Button>
        </NavLink>

        <NavLink to="ask-question" exact="true">
          <Button
            leftIcon={<FiUpload />}
            {...questionLinkStyles}
            isActive={isActive("/ask-question")}
          >
            Ask Question (upload)
          </Button>
        </NavLink>

        <NavLink to="asked-questions" exact="true">
          <Button
            leftIcon={<MdQuestionAnswer />}
            {...questionLinkStyles}
            isActive={isActive("/asked-questions")}
          >
            All Asked Questions
          </Button>
        </NavLink>

        <NavLink to="ai-voice-talk" exact="true">
          <Button
            leftIcon={<FaBrain />}
            {...aiLinkStyles}
            isActive={isActive("/ai-voice-talk")}
          >
            Talk to AI
          </Button>
        </NavLink>

        <NavLink to="analytics" exact="true">
          <Button
            leftIcon={<FaChartPie />}
            {...analyticsLinkStyles}
            isActive={isActive("/analytics")}
          >
            360° AI Analytics
          </Button>
        </NavLink>

        <Button
          leftIcon={<FaSignOutAlt />}
          {...relatedLinkStyles}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </>
    );
  };

  return (
    <HStack
      w="100%"
      minH="100vh"
      align="start"
      spacing={0}
      bg={useColorModeValue("gray.50", "gray.900")}
    >
      {isLargeScreen ? (
        <MotionVStack
          as="nav"
          align="stretch"
          spacing={{ base: 1, md: 1, lg: 2 }}
          p={4}
          bg={navBg}
          color={textColor}
          minW={navWidth}
          w={navWidth}
          borderRight="1px"
          borderColor={"gray.400"}
          minHeight={"110vh"}
          display={"flex"}
          flexDirection={"column"}
          initial={{ x: -250 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 100 }}
          whileHover={{ scale: 1.02 }}
          position={"fixed"}
        >
          <Link to="/">
            <HStack spacing={2} cursor="pointer">
              {" "}
              {/* Adds horizontal spacing between the logo and text */}
              <Image
                src="/logo.png" // Reference to the image in the public folder
                alt="Logo"
                boxSize="40px" // Adjust size of the logo
              />
              <Heading
                size="lg"
                mb={{ base: 1, lg: 2 }}
                pl={2}
                mt={{ base: 1, lg: 2 }}
              >
                TryExam AI
              </Heading>
            </HStack>
          </Link>
          <Divider />
          <NavLinks />
        </MotionVStack>
      ) : (
        <>
          <IconButton
            aria-label="Open Menu"
            icon={<HamburgerIcon />}
            onClick={toggleDrawer}
            m={4}
            fontWeight={"bold"}
            position={"absolute"}
          />
          <Text
            right={"5%"}
            position={"absolute"}
            m="4"
            fontSize={"x-large"}
            fontWeight={"bold"}
          >
            TryExam AI
          </Text>
          <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
            <DrawerOverlay>
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>TryExam AI</DrawerHeader>
                <DrawerBody>
                  <VStack align="stretch" spacing={{ base: 1, lg: 2 }}>
                    <NavLinks />
                  </VStack>
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
        </>
      )}

      {!isNoLayoutRoute ? (
        <Box
          flex="1"
          width={{ base: "100%", lg: "80%" }}
          mt={{ base: "15%", md: "10%", lg: "0%" }}
          position={"absolute"}
          left={{ base: "0px", lg: "300px" }}
          px="auto"
        >
          <Outlet />
        </Box>
      ) : (
        <FeatureList></FeatureList>
      )}
    </HStack>
  );
};

export default Dashboard;
